import { ToggleOff } from 'components/icons/toggleoff';
import { ToggleOn } from 'components/icons/toggleon';
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper';
import { EditableDropdownCell, EditCellState } from 'generic/editCell';
import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Roles } from '../../../shared/constants'
import { SearchAccount } from '../../common/search-accounts/component'

interface EditNewsletterEditCellState extends EditCellState {
  accountCancelReasonId: string,
  accountCancelReasonComments: string,
  showConfirmationModal: boolean,
  confirmationTitle: string,
  confirmationMessage: string,
  actionType: number,
  refundOnCancel: boolean,
  transferUsers: boolean,
  isSuperUser: boolean,
  selectedAccountId: number,
}

enum actionType {
 archive,
 cancelAccount,
 suspendAccount,
 removeAccountSuspension,
 }

const ACCOUNT_IS_SUSPENDED_FIELD = 'is_suspended'
interface AccountCancelReason {
  text: string,
  id: string
}
const ACCOUNT_CANCEL_REASONS: AccountCancelReason[] = [
  {   text: 'Please select a cancellation reason', id: 'select' },
  {   text: 'Fraud', id: 'fraud' },
  {   text: 'Other', id: 'other' },
]

export class EditNewsletterEditCell extends EditableDropdownCell<any, EditNewsletterEditCellState> {
  public static contextType = RasaContext
  constructor(p) {
    super(p)
    this.state = {
      accountCancelReasonComments: '',
      accountCancelReasonId: 'select',
      actionType: 0,
      confirmationMessage: '',
      confirmationTitle: '',
      drop: false,
      refundOnCancel: false,
      showConfirmationModal: false,
      transferUsers: false,
      isSuperUser: false,
      selectedAccountId: 0,
      showArchiveConfimationModal: false,
    }
  }

  public componentDidMount() {
    if (this.context && this.context.user && this.context.user.activeCommunity) {
      this.setState({
        isSuperUser: this.context.user.activeCommunity.role === Roles.super_admin
      })
    }
  }

  public getConfirmationModalJSX(dataItem: any) {
    return <div>
      <Modal isOpen={this.state.showConfirmationModal}
        className="archive-confirmation-modal"
        size="lg"
        fade={false}
        centered={true} >
        <ModalHeader>{this.state.confirmationTitle}</ModalHeader>
        <ModalBody className="archive-confirmation-modal-body">
          <div>
            <h4>Are You Sure?</h4>
            {
              this.state.actionType === actionType.cancelAccount
              ? this.cancelAccountConfirmationMessage(dataItem)
              : <div  dangerouslySetInnerHTML={{
                __html: this.state.confirmationMessage,
              }}/>
            }
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={this.onConfirmation} disabled={this.validateButtonDisabled()}>
            Yes
          </Button>
          <Button onClick={this.onModalClose}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  }

  protected onArchive(event: any) {
    this.setState({
      confirmationTitle: 'Newsletter Archive Confirmation',
      confirmationMessage: `You are about to archive <b> ${ this.props.dataItem.community_name ? this.props.dataItem.community_name : 'Newsletter' }.</b> Would you like to proceed?`,
      actionType: actionType.archive,
      showConfirmationModal: true })
  }

  protected onCancelAccount(event: any) {
    this.setState({
      confirmationTitle: 'Account Cancel Confirmation',
      confirmationMessage: '',
      actionType: actionType.cancelAccount,
      showConfirmationModal: true })
  }

  protected cancelAccountConfirmationMessage(dataItem: any) {
    return <div className="account-cancel-confirmation">
      You are about to cancel account for newsletter <b> {dataItem.community_name ? dataItem.community_name : 'Newsletter' }.</b> Would you like to proceed?
      <div>
        <div className="account-cancel-reason">
          <label className="account-cancel-reason-label">Reason for cancelling</label>
          <select
            onChange={(e) => this.handleAccountCancelReasonChanged(e) }
            className={'account-cancel-reason-select'}
            value={this.state.accountCancelReasonId}
          >
            {ACCOUNT_CANCEL_REASONS.map((item, i) => <option value={item.id} key={i}>{item.text}</option> )}
          </select>
          <textarea
            className={'account-cancel-reason-comments'}
            hidden={this.state.accountCancelReasonId !== 'other'}
            onChange={(e) => this.handleAccountCancelReasonCommentsChanged(e)}
            placeholder="Cancel reason?"
            value={this.state.accountCancelReasonComments}
          />
        </div>
        <div className="account-cancel-refund-container">
          <label className="account-cancel-refund-label">Refund on cancel</label>
          <div onClick={(e) => this.refundOnCancelChanged(e)} className="toggle-active">
            <button className="toggle-button" style={{background: 'none', border: 'none', outline: 'none'}}>
              {this.state.refundOnCancel ? <ToggleOn/> : <ToggleOff/>}
            </button>
          </div>
        </div>
        {this.state.isSuperUser && <>
          <div className="account-cancel-refund-container">
            <label className="account-cancel-refund-label">Transfer Users</label>
            <div onClick={(e) => this.transferUsersChanged(e)} className="toggle-active">
              <button className="toggle-button" style={{background: 'none', border: 'none', outline: 'none'}}>
                {this.state.transferUsers ? <ToggleOn/> : <ToggleOff/>}
              </button>
            </div>
          </div>
          {this.state.transferUsers &&
            <SearchAccount onAccountSelect = {this.handleAccountSelectCallback}/>}
        </>}
      </div>
    </div>
  }

  protected onSuspendAccount(event: any) {
    this.setState({
      confirmationTitle: 'Newsletter Suspend Account Confirmation',
      confirmationMessage: `You are about to supspend account for newsletter <b> ${this.props.dataItem.community_name ? this.props.dataItem.community_name : 'Newsletter'}.</b> Would you like to proceed?`,
      actionType: actionType.suspendAccount,
      showConfirmationModal: true
    })
  }

  protected onActivateAccount(event: any) {
    this.setState({
      confirmationTitle: 'Newsletter Remove Suspension Account Confirmation',
      confirmationMessage: `You are about to remove supension of account for newsletter <b> ${ this.props.dataItem.community_name ? this.props.dataItem.community_name : 'Newsletter' }.</b> Would you like to proceed?`,
      actionType: actionType.suspendAccount,
      showConfirmationModal: true })
  }

  protected hideDots = () => {
    return <div>&nbsp;{this.props.dataItem.community_expiration_date ? <div>&nbsp;</div> : null } </div>
  }

  private onModalClose = (event: any) => {
    this.setState({
      showConfirmationModal: false,
    })
  }

  private handleAccountCancelReasonChanged(e) {
    this.setState({
      accountCancelReasonId: e.currentTarget.value,
    })
  }

  private validateButtonDisabled(): boolean {
    if (this.state.actionType === actionType.cancelAccount) {
      return this.state.accountCancelReasonId === 'select'
      || (this.state.accountCancelReasonId === 'other' && this.state.accountCancelReasonComments === '')
    }

    return false
  }

  private refundOnCancelChanged(e) {
    this.setState({
      refundOnCancel: !this.state.refundOnCancel,
    })
  }

  private transferUsersChanged(e) {
    this.setState({
      transferUsers: !this.state.transferUsers,
    })
  }

  protected handleAccountSelectCallback = (accountData) => {
    this.setState({selectedAccountId: accountData.id})
  }

  private handleAccountCancelReasonCommentsChanged(e) {
    this.setState({
      accountCancelReasonComments: e.currentTarget.value,
    })
  }

  private onConfirmation = (e) => {
    switch (this.state.actionType) {
      case actionType.archive:
        this.onConfirmArchive(e)
        break;
      case actionType.cancelAccount:
        this.onConfirmCancelAccount(e)
        break;
      case actionType.suspendAccount:
      case actionType.removeAccountSuspension:
        this.onConfirmSuspendAccount(e)
      default:
        break;
    }
  }

  private onConfirmArchive = (event: any) => {
    if (this.props.onArchive) {
      this.props.onArchive(this.props.dataItem)
    } else {
      this.props.onChange({
        dataItem: this.props.dataItem,
        syntheticEvent: event,
        field: 'is_archived',
        value: true,
      })
    }
    this.setState({
      showConfirmationModal: false,
    })
  }

  private onConfirmCancelAccount = (event: any) => {
    const cancelData = {
      transferToAccountId: this.state.transferUsers ? this.state.selectedAccountId : null,
      changeReasonId: this.state.accountCancelReasonId,
      changeReasonComments: this.state.accountCancelReasonComments,
      refundOnCancel: this.state.refundOnCancel, // if refundOnCancel is true, cancel immediately
    }
    const url: string = AjaxWrapper.getServerUrl() +
                          `/subscription-billing/cancel-subscription/${this.props.dataItem.community_identifier}`
    AjaxWrapper.ajax(url, HttpMethod.POST, { body: cancelData })
    .then(() => {
      this.setState({
        showConfirmationModal: false,
      })
      window.location.reload()
    })
  }

  private onConfirmSuspendAccount = (event: any) => {
    this.context.entityMetadata.getEntityObject('account').then(
      (entityObject: any ) => {
      entityObject.setRecordId(this.props.dataItem.id, this.props.dataItem.community_account_id)
      entityObject.data[ACCOUNT_IS_SUSPENDED_FIELD] = !this.props.dataItem.community_account_suspended
      entityObject.save()
      this.setState({
      showConfirmationModal: false,
      })
      window.location.reload()
    })
  }
}
